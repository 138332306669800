const Facebook = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="white"/>
            <g clipPath="url(#clip0_335_4143)">
                <path d="M19.2539 16.625H17.2031V22.75H14.4688V16.625H12.2266V14.1094H14.4688V12.168C14.4688 9.98047 15.7812 8.75 17.7773 8.75C18.7344 8.75 19.7461 8.94141 19.7461 8.94141V11.1016H18.625C17.5312 11.1016 17.2031 11.7578 17.2031 12.4688V14.1094H19.6367L19.2539 16.625Z" fill="#0F0F0F"/>
            </g>
            <defs>
                <clipPath id="clip0_335_4143">
                    <rect width="20" height="20" fill="white" transform="translate(6 6)"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default Facebook

import './index.scss';
import Button from './../../components/button'

function Bloc7({setPopupOpen}) {
    return (
        <div className="bloc7"  data-scroll-section id="bloc7">
            <div className="content">
                <div className="left" data-scroll
                     data-scroll-direction="vertical"
                     data-scroll-speed=".5">
                    <h1>
                        Et Swaive,
                        <span>comment ça <br/>marche ?</span>
                    </h1>
                    <p>Swaive vous permet d’investir sur des placements sans risque puis d’en suivre l’évolution en ligne, tout en bénéficiant d’un accompagnement continu.</p>
                    <div>
                        <Button onClick={()=>setPopupOpen(true)} text="VOIR NOS PLACEMENTS" isBlack isLarge/>
                        <Button onClick={()=>setPopupOpen(true)} text="S’INSCRIRE" withBorder isLarge/>
                    </div>
                </div>
                <div className="right" data-scroll
                     data-scroll-direction="vertical"
                     data-scroll-speed="-.5">
                    <div className="bullet">
                        <span>01</span>
                        <p>Décrivez votre besoin
                            <span>Nous vous proposons les meilleurs placements en fonction de  votre situation.</span>
                        </p>
                    </div>
                    <div className="bullet">
                        <span>02</span>
                        <p>Souscrivez en ligne
                            <span>Créez votre compte Swaive et placez votre argent en quelques minutes. </span>
                        </p>
                    </div>
                    <div className="bullet">
                        <span>03</span>
                        <p>Surveillez vos placements
                        <span>Gardez un œil sur vos placements à tout moment : intérêts, soldes, opérations…</span></p>
                    </div>
                    <div className="bullet">
                        <span>04</span>
                        <p>Gardez contact
                        <span>Notre équipe de conseillers vous guide en cas de besoin, par téléphone ou par e-mail.</span></p>
                    </div>
                    <div className='button-container'>
                      <Button onClick={()=>setPopupOpen(true)} text="VOIR NOS PLACEMENTS" isBlack isLarge/>
                      <Button onClick={()=>setPopupOpen(true)} text="S’INSCRIRE" withBorder isLarge/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bloc7;

import './index.scss';
import Icon from './../../components/icon'
import Menu from './../../components/menu'

import VideoMp4 from './../../assets/hero1.mp4';
import VideoWebM from './../../assets/hero1.webm';

function Hero({setPopupOpen, setMenuIsOpen, formState, onProspectSubmit, onEmailChange}) {
    return (
        <div className="hero"  data-scroll-section>
            <Menu setPopupOpen={setPopupOpen} setMenuIsOpen={setMenuIsOpen}/>
            <div className="content" data-scroll>
                <h1>
                    Ne laissez plus votre argent
                    <span className="black">dormir sur votre compte courant.</span>
                </h1>
                <p>
                    Épargne personnelle ou trésorerie professionnelle,
                    <span>rémunérez votre argent sans prise de risque.</span>
                </p>
                <form className="input-block" onSubmit={(e)=> {onProspectSubmit(); e.preventDefault(); }}>
                    <input
                      onChange={(e) => onEmailChange(e.target.value)}
                      value={formState.email}
                      type="email"
                      placeholder='Entrer adresse email...'
                      required={true}
                    />
                    <button
                      type={"submit"}
                      className={`${formState.isLoading && 'isLoading'}`}
                      disabled={formState.isLoading || (formState.message && formState.message.type === 'success')}
                    >
                        {formState.isLoading ? <Icon name="loader"/> : 'REJOINDRE LA LISTE D’ATTENTE'}
                    </button>
                </form>
                {
                    /**
                     * Add class "success" or "error" to the message div
                     * */
                }
                {
                  formState.message && <div className={`message ${formState.message.type}`}>{formState.message.message}</div>
                }
                <div className="lines">
                    <div className="check">
                        <Icon name='check'/>
                        <p>Garanties sur le capital et les intérêts</p>
                    </div>
                    <div className="check">
                        <Icon name='check'/>
                        <p>Souscription en quelques clics</p>
                    </div>
                    <div className="check">
                        <Icon name='check'/>
                        <p>Auprès des meilleures banques</p>
                    </div>
                </div>
                <video
                    loop
                    muted
                    autoPlay
                    playsInline
                    disablePictureInPicture
                >
                    <source src={VideoMp4} type='video/mp4; codecs="hvc1"' />
                    <source src={VideoWebM} type="video/webm" />
                </video>
            </div>
        </div>
    );
}

export default Hero;

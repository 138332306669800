import './index.scss';
import Button from './../../components/button'

function Bloc1({setPopupOpen}) {
    return (
        <div className="bloc1"  data-scroll-section>
            <div className="content">
                <div className="left" data-scroll
                     data-scroll-direction="vertical"
                     data-scroll-speed="1" data-scroll-offset="25%">
                    <p>
                        Sérénité.
                        <span>Épargnez-vous :</span>
                    </p>
                    <div>
                        <Button onClick={()=>setPopupOpen(true)} text="VOIR NOS PLACEMENTS" isBlack isLarge/>
                        <Button onClick={()=>setPopupOpen(true)} text="S’INSCRIRE" withBorder isLarge/>
                    </div>
                </div>
                <div className="right" data-scroll data-scroll-direction="vertical" data-scroll-offset="25%">
                    <div className="bullet">
                        <span>01</span>
                        <p>La peur de perdre votre capital</p>
                    </div>
                    <div className="bullet">
                        <span>02</span>
                        <p>Le stress que votre argent ne soit pas disponible</p>
                    </div>
                    <div className="bullet">
                        <span>03</span>
                        <p>La charge mentale de ne pas comprendre vos placements</p>
                    </div>
                    <div>
                      <Button onClick={()=>setPopupOpen(true)} text="VOIR NOS PLACEMENTS" isBlack isLarge/>
                      <Button onClick={()=>setPopupOpen(true)} text="S’INSCRIRE" withBorder isLarge/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bloc1;

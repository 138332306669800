import {useState} from 'react';
import './index.scss';
import Slider from '@mui/material/Slider';
import useMediaQuery from '@mui/material/useMediaQuery';
import SliderDot from '../../assets/slider-dot.png'
const pourcentage = 4.2

function Bloc3() {
    const [value, setValue] = useState(Math.round((30000 / 100) * pourcentage))
    let Euro = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    });

    const isMobile = useMediaQuery('(max-width:1176px)');

    return (
        <div className="bloc3" data-scroll-section id="bloc3">
            <div className="content" data-scroll
                 data-scroll-direction="vertical" data-scroll-offset="25%">
                <h1>Vous ne le savez pas, mais cette année,
                    <span>vous avez perdu beaucoup d’argent.</span>
                </h1>
                <p>Quelle somme dort <br/> sur vos comptes courants ?</p>
                <Slider
                    min={0}
                    max={100000}
                    step={50}
                    defaultValue={30000}
                    valueLabelDisplay="on"
                    onChange={(_,value)=>setValue(Math.round((value / 100) * pourcentage))}
                    valueLabelFormat={(value)=>Euro.format(value).replace(/\D00(?=\D*$)/, '')}
                    sx={{
                        height:2,
                        "&.MuiSlider-root": {
                            width:isMobile ? '100%' : 576,
                        },
                        '& .Mui-focusVisible' : {},
                        '& .MuiSlider-rail': {
                            height:10,
                            border: '.5px solid rgba(255, 255, 255, 0.5)',
                            backgroundColor:'rgba(255, 255, 255, 0.5)',
                            borderRadius:10
                        },
                        '& .MuiSlider-track': {
                            backgroundColor:'rgba(128, 141, 110, 1)',
                            border:'none',
                            height:8
                        },
                        '& .MuiSlider-valueLabel': {
                            backgroundColor:'transparent',
                            color:'#0F0F0F',
                            width:94,
                            height:32,
                            borderRadius:32,
                            border:'1px solid #0F0F0F',
                            padding:0,
                            fontWeight:400,
                            fontSize:16,
                            lineHeight:24,
                            letterSpacing:'0.05em',
                            transform:'translateY(45px)',
                            overflow:'hidden'
                        },
                        '& .MuiSlider-valueLabelOpen': {
                            transform:'translateY(45px)!important'
                        },
                        '& .MuiSlider-thumb': {
                            width:32,
                            height:32,
                            boxShadow:'none',
                            backgroundSize:'cover',
                            backgroundImage:`url(${SliderDot})`,
                            backgroundColor:'transparent',
                            transform:'translate(-13px, -13px)',
                            '&:before' : {
                                boxShadow:'none',
                            },
                            '&:after' : {
                                boxShadow:'none',
                            },
                        },
                    }}
                />
                <p>Cette année vous auriez pu gagner :</p>
                <span className="price">{value}<span>€</span></span>
            </div>
        </div>
    );
}

export default Bloc3;

const Instagram = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="6" fill="white"/>
            <path d="M19.5 9H12.5C10.567 9 9 10.567 9 12.5V19.5C9 21.433 10.567 23 12.5 23H19.5C21.433 23 23 21.433 23 19.5V12.5C23 10.567 21.433 9 19.5 9Z" stroke="#0F0F0F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.9675 15.56C19.0601 16.1841 18.9535 16.8216 18.6629 17.3817C18.3722 17.9418 17.9124 18.396 17.3488 18.6797C16.7851 18.9634 16.1464 19.0621 15.5234 18.9619C14.9004 18.8616 14.3249 18.5675 13.8787 18.1213C13.4325 17.6751 13.1384 17.0996 13.0381 16.4766C12.9379 15.8536 13.0366 15.2149 13.3203 14.6512C13.604 14.0876 14.0582 13.6278 14.6183 13.3371C15.1784 13.0465 15.8159 12.9399 16.4401 13.0325C17.0767 13.1269 17.6662 13.4235 18.1213 13.8787C18.5765 14.3338 18.8731 14.9233 18.9675 15.56Z" stroke="#0F0F0F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default Instagram

const Loader = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2V6" stroke="#F6F6F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 18V22" stroke="#F6F6F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.92969 4.93005L7.75969 7.76005" stroke="#F6F6F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.2402 16.24L19.0702 19.07" stroke="#F6F6F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 12H6" stroke="#F6F6F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18 12H22" stroke="#F6F6F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.92969 19.07L7.75969 16.24" stroke="#F6F6F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.2402 7.76005L19.0702 4.93005" stroke="#F6F6F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default Loader

const Check = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_335_3039)">
                <g filter="url(#filter0_i_335_3039)">
                    <path d="M5.29199 10.7221L7.45028 13.6782C8.12675 14.6047 9.51524 14.5867 10.1675 13.6429L15.7087 5.625" stroke="#0F0F0F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
            <defs>
                <filter id="filter0_i_335_3039" x="4.54199" y="4.875" width="11.917" height="10.4373" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="0.2"/>
                    <feGaussianBlur stdDeviation="0.25"/>
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"/>
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_335_3039"/>
                </filter>
                <clipPath id="clip0_335_3039">
                    <rect x="0.5" width="20" height="20" rx="4" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default Check

import {useEffect} from 'react';
import './index.scss';
import Icon from "../icon";

function Popup({popupOpen, setPopupOpen, formState, onProspectSubmit, onEmailChange}) {

    useEffect(()=>{
        if(popupOpen){
            document.querySelector("html").classList.add('scroll-disable');
        }else{
            document.querySelector("html").classList.remove('scroll-disable');
        }

    },[popupOpen])

    if(!popupOpen) return null;

    return (
        <div onClick={(e)=> { setPopupOpen(false);  }} className="popup" data-scroll-section>
            <div className="container"
                 onClick={(e)=>{ e.stopPropagation(); }}
            >
                <p>Épargne personnelle ou trésorerie professionnelle, rémunérez votre argent sans prise de risque.</p>
                <form className="input-block"
                      onClick={(e) => { e.stopPropagation(); }}
                      onSubmit={(e)=> { onProspectSubmit(); e.preventDefault(); }}
                >
                    <input type="email"
                           placeholder='Entrer adresse email...'
                           required={true}
                           onChange={(e) => onEmailChange(e.target.value)}
                           value={formState.email}
                    />
                    <button type={"submit"}
                            className={`${formState.isLoading && 'isLoading'}`}
                            disabled={formState.isLoading || (formState.message && formState.message.type === 'success')}
                    >{formState.isLoading ? <Icon name="loader"/> : 'REJOINDRE LA LISTE D’ATTENTE'}</button>
                </form>
                {
                  formState.message && <div className={`message ${formState.message.type}`}>{formState.message.message}</div>
                }
            </div>
        </div>
    );
}

export default Popup;

import './index.scss';
import Left from './../../assets/bloc-6-left.jpg'
import Right from './../../assets/bloc-6-right.jpg'

function Bloc6() {
    return (
        <div className="bloc6"  data-scroll-section>
            <div className="content">
                <div className="left" data-scroll
                     data-scroll-direction="vertical">
                    <h1 data-scroll
                        data-scroll-direction="vertical"
                        data-scroll-speed="1">
                        Épargne sécurisée,
                        <span>gains maximisés.</span>
                    </h1>
                    <div>
                        <img className={'left-picture'} src={Left} alt="" data-scroll
                             data-scroll-direction="vertical"
                             data-scroll-speed="1"/>
                        <div data-scroll
                             data-scroll-direction="vertical"
                             data-scroll-speed="-1">
                            <img className={'right-picture'} src={Right} alt=""/>
                            <p>
                                Vous avez de l'argent de côté mais vous ne comptez pas l'utiliser immédiatement ?
                                <br/><br/>
                                Swaive vous permet d’investir votre argent sans risque auprès des meilleures banques. Ces placements bénéficient d’une garantie en capital à 100%, et d’intérêts garantis dès la souscription.
                                <br/><br/>
                                Aussi, votre argent n’est pas bloqué pendant plusieurs années. Vous vous engagez sur des durées très courtes... dès 3 mois (ou même 1 jour)!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bloc6;

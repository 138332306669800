import './index.scss';
import Button from './../../components/button'

function Bloc5({setPopupOpen}) {
    return (
        <div className="bloc5"  data-scroll-section id="bloc5">
            <div className="content" data-scroll
                 data-scroll-direction="vertical">
                <h1>
                    Votre argent rémunéré,
                    <span>en toute sérénité.</span>
                </h1>
                <div className="table">
                    <div className="top-row">
                        <div></div>
                        <div>
                            <p>Livrets</p>
                            <Button onClick={()=>setPopupOpen(true)} text='DÉCOUVRIR' isBlack isLarge style={{width:245}}/>
                        </div>
                        <div>
                            <p>Comptes à terme</p>
                            <Button onClick={()=>setPopupOpen(true)} text='DÉCOUVRIR' isBlack isLarge style={{width:245}}/>
                        </div>
                        <div>
                            <p>Placements sur mesure</p>
                            <Button onClick={()=>setPopupOpen(true)} text='DÉCOUVRIR' isBlack isLarge style={{width:245}}/>
                        </div>
                    </div>
                    <div className="row">
                        <p>Garantie en capital</p>
                        <p>100% à tout instant</p>
                        <p>100% à tout instant</p>
                        <p>100%</p>
                    </div>
                    <div className="row">
                        <p>Sécurisation des gains</p>
                        <p>journalière</p>
                        <p>à l’échéance</p>
                        <p>variable selon le placement</p>
                    </div>
                    <div className="row">
                        <p>Disponibilité des fonds</p>
                        <p>à tout instant</p>
                        <p>sous 32 jours</p>
                        <p>variable selon le placement</p>
                    </div>
                    <div className="row">
                        <p>Niveau de gains espéré</p>
                        <p>+</p>
                        <p>++</p>
                        <p>+++</p>
                    </div>
                </div>
                <div className="table-mobile-container">
                    <div className="table-mobile">
                        <div className="header">
                            <p>Livrets</p>
                            <Button onClick={()=>setPopupOpen(true)} text="DÉCOUVRIR" isBlack isLarge/>
                        </div>
                        <div className="row">
                            <p>Garantie en capital</p>
                            <span>100% à tout instant</span>
                        </div>
                        <div className="row">
                            <p>Sécurisation des gains</p>
                            <span>journalière</span>
                        </div>
                        <div className="row">
                            <p>Disponibilité des fonds</p>
                            <span>à tout instant</span>
                        </div>
                        <div className="row">
                            <p>Niveau de gains espéré</p>
                            <span>+</span>
                        </div>
                    </div>
                    <div className="table-mobile">
                        <div className="header">
                            <p>Comptes à terme</p>
                            <Button onClick={()=>setPopupOpen(true)} text="DÉCOUVRIR" isBlack isLarge/>
                        </div>
                        <div className="row">
                            <p>Garantie en capital</p>
                            <span>100% à tout instant</span>
                        </div>
                        <div className="row">
                            <p>Sécurisation des gains</p>
                            <span>à l’échéance</span>
                        </div>
                        <div className="row">
                            <p>Disponibilité des fonds</p>
                            <span>sous 32 jours</span>
                        </div>
                        <div className="row">
                            <p>Niveau de gains espéré</p>
                            <span>++</span>
                        </div>
                    </div>
                    <div className="table-mobile">
                        <div className="header">
                            <p>Placements sur mesure</p>
                            <Button onClick={()=>setPopupOpen(true)} text="DÉCOUVRIR" isBlack isLarge/>
                        </div>
                        <div className="row">
                            <p>Garantie en capital</p>
                            <span>100%</span>
                        </div>
                        <div className="row">
                            <p>Sécurisation des gains</p>
                            <span>variable selon le placement</span>
                        </div>
                        <div className="row">
                            <p>Disponibilité des fonds</p>
                            <span>variable selon le placement</span>
                        </div>
                        <div className="row">
                            <p>Niveau de gains espéré</p>
                            <span>+++</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bloc5;

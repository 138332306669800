import './index.scss';

function Button({onClick, text,withBorder, isBlack, isLarge, style, fontWeight = '400'}) {
    return (
        <div onClick={()=>onClick()} style={style} className={`button ${withBorder && 'border'} ${isBlack && 'isBlack back-bck'} ${isLarge && 'isLarge'}`}>
            <span>{text}</span>
        </div>
    );
}

export default Button;

import './index.scss';
import Right from './../../assets/bloc2-right.jpg'
import Icon from './../../components/icon';

import Picture from './../../assets/picture-left.jpg'

// import VideoMp4 from './../../assets/1.mp4';
// import VideoWebM from './../../assets/1.webm';

function Bloc2() {
    return (
        <div className="bloc2" data-scroll-section id="bloc2">
            <div className="content">
                <div className="left" data-scroll
                     data-scroll-direction="vertical"
                     data-scroll-speed="-1" data-scroll-offset="25%">
                    <h1>Un mot d’ordre ?
                        <span>
                            Sécurité.
                        </span>
                    </h1>
                    <div className="checks">
                        <div className="checks-container">
                            <div className="check">
                                <Icon name='check-filled'/>
                                <p>Capital garanti à 100%</p>
                            </div>
                            <div className="check">
                                <Icon name='check-filled'/>
                                <p>Disponibilité rapide des fonds</p>
                            </div>
                            <div className="check">
                                <Icon name='check-filled'/>
                                <p>Intérêts garantis à l’échéance</p>
                            </div>
                            <div className="check">
                                <Icon name='check-filled'/>
                                <p>Accompagnement et suivi personnalisé</p>
                            </div>
                        </div>
                        <img src={Picture} alt=""/>
                        {/*<video
                            loop
                            muted
                            autoPlay
                            playsInline
                            disablePictureInPicture
                        >
                            <source src={VideoMp4} type='video/mp4; codecs="hvc1"' />
                            <source src={VideoWebM} type="video/webm" />
                        </video>*/}
                    </div>
                </div>
                <div className="right" id='stick'>
                    <img src={Right} alt="screenshot of swaive" data-scroll
                         data-scroll-direction="vertical"
                         data-scroll-speed="1"/>
                </div>
            </div>
        </div>
    );
}

export default Bloc2;

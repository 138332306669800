import './index.scss';
import Icon from './../../components/icon'

function Footer() {
    return (
        <div className="footer back-bck"  data-scroll-section>
            <div className="content">
                <div>
                    <div className='social'>
                        <Icon name='logo' color="#FFFFFF"/>
                        <div>
                            <a href="#">
                                <Icon name='facebook'/>
                            </a>
                            <a href="#">
                                <Icon name='instagram'/>
                            </a>
                        </div>
                    </div>
                    <div className='links'>
                        <div>
                            <h3 className='white'>À propos</h3>
                            <a href="#">L’équipe</a>
                            <a href="#">Certifications</a>
                            <a href="#">Rejoignez-nous</a>
                            <a href="#">Devenez partenaire</a>
                        </div>
                        <div>
                            <h3 className='white'>Nos placements</h3>
                            <a href="#">Livrets</a>
                            <a href="#">Comptes à terme</a>
                            <a href="#">Placements sur mesure</a>
                        </div>
                        <div>
                            <h3 className='white'>Informations</h3>
                            <a href="#">Mentions légales</a>
                            <a href="#">CGU</a>
                            <a href="#">Aide</a>
                        </div>
                        <div>
                            <h3 className='white'>Contact</h3>
                            <a href="mailto:contact@swaive.fr">contact@swaive.fr</a>
                        </div>
                    </div>
                </div>
                <p>Copyright © 2024 Swaive</p>
            </div>
        </div>
    );
}

export default Footer;

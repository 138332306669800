import './index.scss';
import Icon from './../icon'

function Bandeau({setPopupOpen}) {
    return (
        <div className="bandeau back-bck" data-scroll-section onClick={()=>setPopupOpen(true)}>
            <div className="badge">
                <span className="white">En ce moment</span>
            </div>
            <p className="white">4,0 % garantis sur 6 mois avec nos comptes à terme <Icon name={'arrowRight'}/></p>
            <Icon name={'arrowRight'}/>
        </div>
    );
}

export default Bandeau;

import {useCallback, useRef, useState} from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll'
import Bandeau from "./components/bandeau";
import Hero from "./blocks/hero";
import Bloc1 from "./blocks/bloc-1";
import Bloc2 from "./blocks/bloc-2";
import Bloc3 from "./blocks/bloc-3";
import Bloc4 from "./blocks/bloc-4";
import Bloc5 from "./blocks/bloc-5";
import Bloc6 from "./blocks/bloc-6";
import Bloc7 from "./blocks/bloc-7";
import Footer from "./blocks/footer";
import PopUp from "./components/popup"
import Burger from './components/burger'

function App() {
    const [popupOpen, setPopupOpen] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [formState, setFormState] = useState({
    isLoading: false,
    message: null,
    email: '',
  })

  const ref = useRef(null);

  const handleProspectSubmit = () => {
    (async () => {
      try {
        setFormState((prevState) => ({
          ...prevState,
          message: null,
          isLoading: true,
        }))
        const result = await fetch(
          `${process.env.REACT_APP_API_URL}/v1/prospects`,
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({email: formState.email})
          }
        )
        if (result.status !== 201) {
          throw new Error(`${result.status} ${result.statusText}`)
        }
        setFormState((prevState) => ({
          ...prevState,
          isLoading: false,
          message: {type:"success",message:"Félicitations, nous vous avons bien ajouté à la liste d’attente!"}
        }))
      } catch (error) {
        setFormState((prevState) => ({
          ...prevState,
          isLoading: false,
          message: {type:"error",message:"Une erreur est survenue, veuillez réessayer dans quelques instants."}
        }))
      }
    })()
  }

  const handleEmailChange = (value) => {
    setFormState((prevState) => ({ ...prevState, email: value }))
  };

    const options = {
        smooth: true,
    }

    return (
        <LocomotiveScrollProvider options={options} containerRef={ref}>
            <Burger menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} setPopupOpen={setPopupOpen}/>
            <main data-scroll-container ref={ref}>
                <Bandeau setPopupOpen={setPopupOpen}/>
                <Hero setPopupOpen={setPopupOpen}
                      setMenuIsOpen={setMenuIsOpen}
                      formState={formState}
                      onProspectSubmit={handleProspectSubmit}
                      onEmailChange={handleEmailChange}
                />
                <Bloc1 setPopupOpen={setPopupOpen}/>
                <Bloc2/>
                <Bloc3/>
                <Bloc4 setPopupOpen={setPopupOpen}/>
                <Bloc5 setPopupOpen={setPopupOpen}/>
                <Bloc6/>
                <Bloc7 setPopupOpen={setPopupOpen}/>
                <Footer/>
            </main>
            <PopUp setPopupOpen={setPopupOpen}
                   popupOpen={popupOpen}
                   formState={formState}
                   onProspectSubmit={handleProspectSubmit}
                   onEmailChange={handleEmailChange}
            />
      </LocomotiveScrollProvider>
  );
}

export default App;

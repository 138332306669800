import {useEffect} from 'react';
import './index.scss';
import Icon from './../icon'
import Button from './../button'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

function Burger({menuIsOpen, setMenuIsOpen, setPopupOpen}) {
    const { scroll } = useLocomotiveScroll()

    useEffect(()=>{
        if(menuIsOpen){
            document.querySelector("html").classList.add('scroll-disable');
        }else{
            document.querySelector("html").classList.remove('scroll-disable');
        }

    },[menuIsOpen])


    if(!menuIsOpen){
        return null;
    }
    return (
        <div className="burger-menu" data-scroll-section>
            <div className="burger-top">
                <Icon name={"logo"}/>
                <div onClick={()=>setMenuIsOpen(false)}>
                    <Icon name="close"/>
                </div>
            </div>
            <div className="burger-list">
                <div className='link' onClick={()=>{
                    setMenuIsOpen(false);
                    scroll.scrollTo('#bloc2')
                }}>Notre mission</div>
                <div className='link' onClick={()=>{
                    setMenuIsOpen(false);
                    scroll.scrollTo('#bloc3')
                }}>Simulateur</div>
                <div className='link' onClick={()=>{
                    setMenuIsOpen(false);
                    scroll.scrollTo('#bloc5')
                }}>Nos placements</div>
                <div className='link' onClick={()=>{
                    setMenuIsOpen(false);
                    scroll.scrollTo('#bloc7')
                }}>Comprendre</div>
            </div>
            <div className="button-container">
                <Button onClick={()=>{
                    setPopupOpen(true)
                }} text={'CONNEXION'}/>
                <Button onClick={()=>{
                    setPopupOpen(true)
                }} text={'S’INSCRIRE'} withBorder={true}/>
            </div>
        </div>
    );
}

export default Burger;

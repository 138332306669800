import './index.scss';
import Button from './../../components/button'
import Picture from './../../assets/bloc4-right.jpg'

function Bloc4({setPopupOpen}) {
    return (
        <div className="bloc4"  data-scroll-section id="bloc4">
            <div className="content">
                <div className="left" data-scroll
                     data-scroll-direction="vertical"
                     data-scroll-speed="1">
                    <h1>
                        Swaive, tout argent
                        <span>mérite salaire.</span>
                    </h1>
                    <p>Avec Swaive, l’argent prend de la valeur simplement avec le temps qui passe. Parfois, il suffit d’attendre.</p>
                    <div className='button-container'>
                        <Button onClick={()=>setPopupOpen(true)} text="VOIR NOS PLACEMENTS" isBlack isLarge/>
                        <Button onClick={()=>setPopupOpen(true)} text="S’INSCRIRE" withBorder isLarge/>
                    </div>
                    <div className="infos">
                        <div className="info">
                            <span>1460</span> milliards <br/>
                            dorment sur des comptes courants, en France.
                        </div>
                        <div className="info">
                            <span>58</span> milliards <br/>
                            d’intérêts n’ont pas été touchés par les Français en 2023.
                        </div>
                        <div className='button-container'>
                          <Button onClick={()=>setPopupOpen(true)} text="VOIR NOS PLACEMENTS" isBlack isLarge/>
                          <Button onClick={()=>setPopupOpen(true)} text="S’INSCRIRE" withBorder isLarge/>
                        </div>
                    </div>
                </div>
                <div className="right" data-scroll
                     data-scroll-direction="vertical"
                     data-scroll-speed="-1">
                    <img src={Picture} alt="background"/>
                </div>
            </div>
        </div>
    );
}

export default Bloc4;

import ArrowRight from './arrowRight'
import Logo from './Logo'
import Check from './check'
import Facebook from './facebook'
import Instagram from './instagram'
import CheckFilled from './checkfilled'
import Menu from './menu'
import Loader from './loader'
import Close from './close'
const Icons = ({name, color}) => {
    switch (name){
        case 'arrowRight':
            return <ArrowRight/>
        case 'logo':
            return <Logo color={color}/>
        case 'check':
            return <Check/>
        case 'check-filled':
            return <CheckFilled/>
        case 'loader':
            return <Loader/>
        case 'close':
            return <Close/>
        case 'facebook':
            return <Facebook/>
        case 'instagram':
            return <Instagram/>
        case 'menu':
            return <Menu/>
        default:
        return null;
    }
}

export default Icons

const ArrowRight = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.458 5.625L16.0413 10L11.458 14.375" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.833 10H3.95801" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export default ArrowRight

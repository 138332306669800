const CheckFilled = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_335_3240)">
                <rect x="6" y="5" width="20" height="20" rx="10" fill="#0F0F0F"/>
                <g clipPath="url(#clip0_335_3240)">
                    <path d="M11.833 15.5777L13.5596 17.9425C14.1008 18.6837 15.2116 18.6693 15.7334 17.9143L20.1663 11.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </g>
            <defs>
                <filter id="filter0_d_335_3240" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_335_3240"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_335_3240" result="shape"/>
                </filter>
                <clipPath id="clip0_335_3240">
                    <rect width="16" height="16" fill="white" transform="translate(8 7)"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default CheckFilled

import './index.scss';
import Icon from './../icon'
import Button from './../button'
import { useLocomotiveScroll } from 'react-locomotive-scroll'

function Menu({setMenuIsOpen,setPopupOpen}) {
    const { scroll } = useLocomotiveScroll()
    return (
        <div className="menu" data-scroll-section>
            <div className="content">
                <div className="box">
                    <Icon name={"logo"}/>
                </div>
                <div className="box middle">
                    <div className="menu-links">
                        <div className='link' onClick={()=>scroll.scrollTo('#bloc2')}>Notre mission</div>
                        <div className='link' onClick={()=>scroll.scrollTo('#bloc3')}>Simulateur</div>
                        <div className='link' onClick={()=>scroll.scrollTo('#bloc5')}>Nos placements</div>
                        <div className='link' onClick={()=>scroll.scrollTo('#bloc7')}>Comprendre</div>
                    </div>
                </div>
                <div className="box button-container">
                    <Button onClick={()=>{setPopupOpen(true)}} text={'CONNEXION'}/>
                    <Button onClick={()=>{setPopupOpen(true)}} text={'S’INSCRIRE'} withBorder={true}/>
                </div>
                <div className="burger">
                    <Button onClick={()=>{setPopupOpen(true)}} text={'S’INSCRIRE'} withBorder={true}/>
                    <div onClick={()=>setMenuIsOpen(true)}>
                      <Icon name="menu"></Icon>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
